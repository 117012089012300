import { capitalize } from 'lodash';

import { Accessory } from '../accessories/accessories.types';
import { Battery } from '../batteries/batteries.types';
import { Charger } from '../chargers/chargers.types';
import { Consumable } from '../consumables/consumables.types';
import { Device } from '../devices/devices.types';
import { GlobalSettings } from '../global-settings/global-settings.types';
import { HardDrive } from '../hard-drives/hard-drives.types';
import { PurchaseItem } from '../purchases/purchases.types';
import { Ram } from '../rams/rams.types';
import { UserCompanyRole } from '../users/users.types';

class DicoLabelsService {
  getStateLabel(key: string): string {
    if (key === 'ongoing') return 'En cours';
    if (key === 'full') return 'Pleine';
    if (key === 'picked') return 'Ramassée';
  }

  getCollectionLabel(key: string, alt?: 'short' | 'long'): string {
    if (key === 'accessory_part_id') return "ID de l'accessoire ou de la pièce détachée";
    if (key === 'analysis_supplier_file') return 'Fichier fournisseur analysable';
    if (key === 'amount') return 'Montant';
    if (key === 'manual_tracking_number') return 'N° de suivi manuel';
    if (key === 'amount_ht') return 'Montant HT';
    if (key === 'amount_ttc') return 'Montant TTC';
    if (key === 'apple_refs') return 'ID Apple';
    if (key === 'battery_ratio' && alt === 'short') return 'Ratio batterie';
    if (key === 'battery_ratio' && alt === 'long') return 'Ratio batterie';
    if (key === 'bic') return 'Code BIC';
    if (key === 'buffer_alert') return 'Alerte stock tampon';
    if (key === 'category') return 'Catégorie';
    if (key === 'chargers') return 'Chargeurs';
    if (key === 'cage') return 'Cage';
    if (key === 'cage_number') return 'Numéro de cage';
    if (key === 'carrier') return 'Transporteur';
    if (key === 'state') return 'État de la cage';
    if (key === 'picked_up_at') return 'Date de ramassage';
    if (key === 'charger_included') return 'Présence du chargeur';
    if (key === 'city') return 'Ville';
    if (key === 'code') return 'Code interne Okamac';
    if (key === 'color') return 'Couleur';
    if (key === 'comment') return 'Commentaire';
    if (key === 'comment-purchase-creation') return 'Création de la commande';
    if (key === 'comment-purchase-pause') return 'Dernière mise en pause';
    if (key === 'comment-purchase-depause') return 'Dernière annulation de la pause';
    if (key === 'company') return 'Société';
    if (key === 'consumables_mix') return "Types d'emballages";
    if (key === 'control_started_at') return 'Contrôle débuté le';
    if (key === 'control_started_by') return 'Contrôle débuté par';
    if (key === 'control_terminated_at') return 'Contrôle terminé le';
    if (key === 'control_terminated_by') return 'Contrôle terminé par';
    if (key === 'country') return 'Pays';
    if (key === 'created_at') return 'Date de création';
    if (key === 'credit_amount') return 'Total des notes de crédit';
    if (key === 'currency') return 'Devise';
    if (key === 'customer') return 'Client';
    if (key === 'customer_name') return 'Client';
    if (key === 'customer_country') return 'Pays';
    if (key === 'customer_slip') return 'Bordereau client';
    if (key === 'customer_return_date') return 'Date de demande du client';
    if (key === 'cycles' && alt === 'short') return 'Cycles';
    if (key === 'cycles' && alt === 'long') return 'Cycles de batterie';
    if (key === 'order_date') return 'Date de commande';
    if (key === 'declared_faulty') return 'Pannes déclarées';
    if (key === 'delivery_expected_date') return 'Date de livraison estimée';
    if (key === 'depaused_at') return 'Annulation de pause le';
    if (key === 'depaused_by') return 'Annulation de pause par';
    if (key === 'desc') return 'Référence détaillée';
    if (key === 'device_count') return "Nombre d'ordinateurs";
    if (key === 'devices_count') return 'Quantité totale';
    if (key === 'devices_count_c') return 'C';
    if (key === 'devices_count_b') return 'B';
    if (key === 'devices_count_a') return 'A';
    if (key === 'devices_count_aa') return 'A+';
    if (key === 'device_id' && alt === 'short') return 'Ordinateur';
    if (key === 'device_id' && alt === 'long') return 'Ordinateur associé';
    if (key === 'devices_in_match_stock') return 'Ordinateurs en stock à vendre';
    if (key === 'devices_mix') return "Modèles d'ordinateurs";
    if (key === 'display_name') return 'Nom';
    if (key === 'email') return 'Email';
    if (key === 'error') return 'Erreur';
    if (key === 'expected_delivery_date') return 'Date de livraison prévue';
    if (key === 'export_type') return "Type d'export";
    if (key === 'express') return 'Commande 24h/72h';
    if (key === 'faulty') return 'Composants défectueux';
    if (key === 'file') return 'Fichier';
    if (key === 'first_name') return 'Prénom';
    if (key === 'freq') return 'Fréquence du processeur';
    if (key === 'grade') return 'Grade';
    if (key === 'grades') return 'Grades';
    if (key === 'graphic_card') return 'Carte Graphique';
    if (key === 'hdsize' && alt === 'short') return 'HD (Taille en Go)';
    if (key === 'hdsize' && alt === 'long') return 'Taille du disque dur (Go)';
    if (key === 'hdtype' && alt === 'short') return 'HD (Type)';
    if (key === 'hdtype' && alt === 'long') return 'Type du disque dur';
    if (key === 'iban') return 'Code IBAN';
    if (key === 'id_okamac') return 'Code interne Okamac';
    if (key === 'incoterm') return 'INCOTERM';
    if (key === 'initial_comment') return 'Commentaire de Contrôle';
    if (key === 'initial_supplier_file') return 'Fichier fournisseur initial';
    if (key === 'invoice_number' && alt === 'short') return 'N° de facture';
    if (key === 'invoice_number' && alt === 'long') return 'Numéro de facture';
    if (key === 'invoice_ref') return 'Numéro de devis / commande / facture';
    if (key === 'is_exchanged' && alt === 'long') return 'Commande échangée';
    if (key === 'is_exchanged' && alt === 'short') return 'Echangée';
    if (key === 'is_favorite') return 'Favori';
    if (key === 'is_paid') return 'Payé ?';
    if (key === 'is_retracted' && alt === 'long') return 'Commande rétractée';
    if (key === 'is_retracted' && alt === 'short') return 'Rétractée';
    if (key === 'is_urgent') return 'Urgent';
    if (key === 'item_type') return 'Type de produits';
    if (key === 'items_mix') return 'Types de produits';
    if (key === 'keyboard' && alt === 'short') return 'Clavier';
    if (key === 'keyboard' && alt === 'long') return 'Langue du clavier';
    if (key === 'keyboard_us') return 'Clavier US';
    if (key === 'last_name' && alt === 'short') return 'Nom';
    if (key === 'last_name' && alt === 'long') return 'Nom de famille';
    if (key === 'last_reception_at' && alt === 'long') return 'Date de livraison réelle';
    if (key === 'litigation_reasons') return 'Litiges';
    if (key === 'location_name') return 'Emplacement';
    if (key === 'current_battery_capacity') return 'Capacité actuelle';
    if (key === 'consumable_types') return "Types d'emballage";
    if (key === 'marketplace') return 'Place de marché';
    if (key === 'marketplace_order_id') return 'Numéro de commande';
    if (key === 'marketplace_line_id') return 'Numéro de ligne';
    if (key === 'display_order_line') return 'Ligne de commande';
    if (key === 'marketplace_order_line_id') return 'SKU PDM';
    if (key === 'marketplace_skus_file') return 'Catalogue SKU';
    if (key === 'marketplace_customer_id') return 'Identifiant client';
    if (key === 'max_shipping_date' && alt === 'short') return "Date max. d'exp.";
    if (key === 'max_shipping_date' && alt === 'long') return "Date max. d'expédition";
    if (key === 'model_identifier') return 'Id du modèle';
    if (key === 'model' && alt === 'short') return 'Modèle';
    if (key === 'model' && alt === 'long') return "Modèle de l'ordinateur";
    if (key === 'models' && alt === 'short') return 'Modèles';
    if (key === 'models' && alt === 'long') return 'Références ordinateurs';
    if (key === 'model_string') return 'Modèle';
    if (key === 'model_namestring' && alt === 'short') return 'Modèle';
    if (key === 'model_namestring' && alt === 'long') return 'Nom du modèle';
    if (key === 'nb_items') return "Nombre d'éléments";
    if (key === 'name') return 'Nom';
    if (key === 'nb_lines') return 'Nb lignes';
    if (key === 'new_match_required' && alt === 'short') return 'Nouvelle asso. nécessaire';
    if (key === 'new_match_required' && alt === 'long') return 'Nouvelle association nécessaire';
    if (key === 'new_battery_required' && alt === 'long') return 'Option batterie neuve';
    if (key === 'new_battery_price' && alt === 'long') return 'Montant batterie neuve';
    if (key === 'new_sku') return 'Nouveau SKU';
    if (key === 'number_cores') return 'Nb de coeurs';
    if (key === 'order_id' && alt === 'short') return 'Commande';
    if (key === 'order_id' && alt === 'long') return 'Commande associée';
    if (key == 'order_returns_nb') return 'Nombre de retours du SN';
    if (key === 'ordered_keyboard') return 'Clavier commandé';
    if (key === 'ordered_mouse') return 'Souris commandée';
    if (key === 'order_line_type') return 'Type de commande';
    if (key === 'ordered_item_namestring') return 'Objet commandé';
    if (key === 'ordered_size') return "Taille d'écran";
    if (key === 'ordered_year') return "Année de l'ordinateur";
    if (key === 'ordered_item_desc') return 'Description';
    if (key === 'orders_file') return 'Fichier de commandes';
    if (key === 'os') return 'OS';
    if (key === 'other_desc') return 'Référence détaillée';
    if (key === 'other_link') return 'Lien internet du produit';
    if (key === 'other_price') return "Prix d'achat";
    if (key === 'other_reason') return "Justification de l'achat (produit et quantité)";
    if (key === 'out_of_stock') return 'Hors stock';
    if (key === 'packaging_photo_file') return "Photo de l'emballage";
    if (key === 'paused') return 'En pause';
    if (key === 'paused_by') return 'Mise en pause par';
    if (key === 'paused_at') return 'Mise en pause le';
    if (key === 'payment_confirmation_file') return 'Confirmation du paiement';
    if (key === 'payment_delay') return 'Délai en jours';
    if (key === 'payment_expected_date') return 'Date du paiement';
    if (key === 'payment_method') return 'Mode de paiement';
    if (key === 'payment_effective_at') return 'Paiement validé le';
    if (key === 'payment_effective_by') return 'Paiement validé par';
    if (key === 'payment_scheduled_at') return 'Paiement demandé le';
    if (key === 'payment_scheduled_by') return 'Paiement demandé par';
    if (key === 'payment_scheduled_delay') return 'Délai en jours';
    if (key === 'payment_scheduled_timing') return 'Délai du paiement';
    if (key === 'payment_supplier_rib_id') return 'RIB du fournisseur';
    if (key === 'payment_timing') return 'Délai du paiement';
    if (key === 'payment_transfer_date') return 'Date de virement';
    if (key === 'phone' && alt === 'short') return 'Téléphone';
    if (key === 'phone' && alt === 'long') return 'Numéro de tél.';
    if (key === 'phone_secondary' && alt === 'short') return 'Téléphone secondaire';
    if (key === 'phone_secondary' && alt === 'long') return 'Numéro de tél. secondaire';
    if (key === 'dial_code') return 'Indicateur';
    if (key === 'postal_code') return 'Code postal';
    if (key === 'total_price') return 'Montant total';
    if (key === 'price') return 'Montant';
    if (key === 'price_avg' && alt === 'short') return 'Prix moyen';
    if (key === 'price_avg' && alt === 'long') return "Prix moyen d'achat";
    if (key === 'price_avg_min') return 'Prix minimum';
    if (key === 'price_avg_max') return 'Prix maximum';
    if (key === 'proc' && alt === 'short') return 'Proc (Type)';
    if (key === 'proc' && alt === 'long') return 'Type de processeur';
    if (key === 'process_handled_by') return 'Technicien';
    if (key === 'product_description') return 'Description';
    if (key === 'purchasable_category') return 'Catégorie de produit';
    if (key === 'purchasable_id') return 'Référence du produit';
    if (key === 'purchasable_type') return 'Type de produit';
    if (key === 'purchase_date') return "Date d'achat";
    if (key === 'purchase_price' && alt === 'short') return 'Prix';
    if (key === 'purchase_price' && alt === 'long') return "Prix d'achat";
    if (key === 'purchase_status' && alt === 'long') return 'Statut de la commande';
    if (key === 'quantity' && alt === 'short') return 'Quantité';
    if (key === 'quantity' && alt === 'long') return 'Quantité en stock';
    if (key === 'quantity_min') return 'Quantité minimum';
    if (key === 'quantity_query_min') return 'Quantité minimum';
    if (key === 'quantity_supplier_min') return 'Quantité fournisseur min.';
    if (key === 'quantity_okamac_min') return 'Quantité Okamac min.';
    if (key === 'quantity_max') return 'Quantité maximum';
    if (key === 'quantity_query_max') return 'Quantité maximum';
    if (key === 'quantity_supplier_max') return 'Quantité fournisseur max.';
    if (key === 'quantity_okamac_max') return 'Quantité Okamac max.';
    if (key === 'quantity_ordered') return 'Quantité commandée';
    if (key === 'quantity_supplier') return 'Quantité chez le fournisseur';
    if (key === 'quantity_total') return 'Quantité totale';
    if (key === 'quantity_desired' && alt === 'short') return 'Quantité souhaitée';
    if (key === 'quantity_desired' && alt === 'long') return 'Quantité souhaitée en stock';
    if (key === 'quantity_in_match_stock') return 'Quantité en stock à vendre';
    if (key === 'quantity_def_in_match_stock') return 'Quantité défectueuse en stock à vendre';
    if (key === 'quantity_query') return 'Quantité souhaitée';
    if (key === 'quantity_already_received') return 'Quantité déjà reçue';
    if (key === 'quantity_received') return 'Quantité reçue';
    if (key === 'querier') return 'Demandeur';
    if (key === 'query_date') return 'Date de la demande';
    if (key === 'ram' && alt === 'short') return 'RAM';
    if (key === 'ram' && alt === 'long') return 'Mémoire RAM';
    if (key === 'reasons') return 'Raisons';
    if (key === 'reception_status') return 'Statut de la réception';
    if (key === 'reference_internal') return 'Référence interne';
    if (key === 'reference_supplier') return 'Référence fournisseur';
    if (key === 'retina') return 'Rétina';
    if (key === 'return_type') return 'Type de retour';
    if (key === 'rma_amount') return 'Montant';
    if (key === 'rma_amount_refunded') return 'Montant remboursé';
    if (key === 'rma_currency') return 'Devise';
    if (key === 'rma_file') return 'Document RMA';
    if (key === 'rma_problem_type') return 'Problème rencontré';
    if (key === 'rma_problem_desc') return 'Description du problème';
    if (key === 'rma_solution') return 'Solution demandée';
    if (key === 'rma_status') return 'Statut du RMA';
    if (key === 'role') return 'Rôle';
    if (key === 'same_address') return "Utiliser l'adresse de livraison";
    if (key === 'serial_no' && alt === 'short') return 'N° série';
    if (key === 'serial_no' && alt === 'long') return 'Numéro de série';
    if (key === 'print_return_label_date') return 'Date de génération';
    if (key === 'shipping_carrier') return 'Transporteur';
    if (key === 'shipping_company') return 'Transporteur';
    if (key === 'shipping_cost') return 'Transport';
    if (key === 'shipping_confirmed_date') return 'Date de livraison confirmée';
    if (key === 'shipping_date' && alt === 'long') return "Date d'expédition";
    if (key === 'shipping_date' && alt === 'short') return "Date d'exp.";
    if (key === 'shipping_fees') return "Frais d'expédition";
    if (key === 'shipping_time' && alt === 'short') return "Délai d'expédition";
    if (key === 'shipping_time' && alt === 'long') return "Délai d'expédition";
    if (key === 'shipping_tracking_number') return 'N° de suivi colis';
    if (key === 'shipping_type') return 'Type de livraison';
    if (key === 'size' && alt === 'short') return 'Écran';
    if (key === 'size' && alt === 'long') return "Taille d'écran";
    if (key === 'sku') return 'SKU Okamac';
    if (key === 'sku_okamac') return 'SKU Okamac';
    if (key === 'sku_marketplace' && alt === 'short') return 'SKU PDM';
    if (key === 'sku_marketplace' && alt === 'long') return 'SKU Place de marché';
    if (key === 'spares_mix') return 'Pièces détachées';
    if (key === 'spare_types') return 'Types de pièces';
    if (key === 'stock' && alt === 'short') return 'Stock';
    if (key === 'stock' && alt === 'long') return 'Déplacer vers le stock';
    if (key === 'status' && alt === 'short') return 'Statut';
    if (key === 'status' && alt === 'long') return 'Choix du statut';
    if (key === 'stock_status_id' && alt === 'long') return 'Stock / Statut';
    if (key === 'status_required') return 'Choix du statut (Obligatoire)';
    if (key === 'street_1') return 'N° et libellé de rue';
    if (key === 'street_2') return "Complément d'adresse";
    if (key === 'sub_categories') return 'Sous catégories';
    if (key === 'sub_category') return 'Sous catégorie';
    if (key === 'supplier' && alt === 'long') return 'Fournisseur';
    if (key === 'supplier_grades') return 'Grades fournisseur';
    if (key === 'supplier_id') return 'Fournisseur';
    if (key === 'supplier_reference') return 'Ref. fournisseur';
    if (key === 'tax_scheme' && alt === 'short') return 'Taxe';
    if (key === 'tax_scheme' && alt === 'long') return 'Régime de taxe';
    if (key === 'tax_schemes' && alt === 'long') return 'Régimes de taxe';
    if (key === 'touchbar') return 'Touchbar';
    if (key === 'updated_at') return 'Dernière modification';
    if (key === 'upgrade_amount') return "Montant de l'upgrade";
    if (key === 'vat_number') return 'Numéro de TVA';
    if (key === 'nif_code') return 'Code NIF';
    if (key === 'validated_at') return 'Validée le';
    if (key === 'validated_by') return 'Validée par';
    if (key === 'warranty') return 'Garantie';
    if (key === 'warranty_amount') return 'Montant de garantie';
    if (key === 'year') return 'Année';
    if (key === 'start_date') return 'Date de début';
    if (key === 'end_date') return 'Date de fin';
    if (key === 'is_eligible_painting') return 'Éligible peinture';
    if (key === 'eligible_polarizer') return 'Éligible filtre polariseur';
    if (key === 'booked_btob') return 'Réservé BtoB';
    if (key === 'computer_type') return "Type d'ordinateurs";
    if (key === 'cart_number') return '# chariot';
    if (key === 'computer_quantity') return "Nombre d'ordinateurs";
    if (key === 'ordered_os') return 'Os commandé';
    if (key === 'date_reception') return 'Réceptionné le';
    if (key === 'sku-difference-cost') return 'MAJ Algo Association';
    if (key === 'visibility_edge_stripes') return 'Rayures à la limite du visible';
    if (key === 'light_stripes') return 'Rayures légères';
    if (key === 'deep_stripes') return 'Rayures profondes';
    if (key === 'visibility_edge_scratches') return 'Éraflures à la limite du visible';
    if (key === 'light_scratches') return 'Éraflures légères';
    if (key === 'deep_scratches') return 'Éraflures profondes';
    if (key === 'light_poc') return 'POC léger';
    if (key === 'deep_poc') return 'POC sévère';
    if (key === 'light_traces') return 'Traces légères';
    if (key === 'blue_spots') return 'Tâches bleu';
    if (key === 'yellow_spots') return 'Tâches jaune';
    if (key === 'pink_screen') return 'Dalle rosé';
    if (key === 'light_leakage') return 'Fuite de lumière';
    if (key === 'white_spots') return 'WhiteSpot';
    if (key === 'case') return 'Coque';
    if (key === 'screen') return 'Dalle';
    if (key === 'topcase') return 'Tranches / Chassis';
    if (key === 'discoloration') return 'Décoloration';
    if (key === 'broken') return 'Dalle Cassé';
    if (key === 'veils') return 'Voilé';
    if (key === 'traces') return 'Traces';
    if (key === 'stikers') return 'Stickers';
    if (key === 'pocon') return 'Impact zone écran';
    if (key === 'pocoff') return 'Impact hors zone écran';
    if (key === 'seal') return 'Joint';
    if (key === 'foot') return 'Pied tordu';
    if (key === 'light') return 'Léger';
    if (key === 'middle') return 'Moyen';
    if (key === 'deep') return 'Important';
    if (key === 'visibility_edge') return 'Limite du visible';
    if (key === 'visible') return 'Voyant';
    if (key === 'unsellable') return 'Très sévère';
    if (key === 'degraded') return 'Dégradé';
    if (key === 'destroyed') return 'Détruit';
    if (key === 'label-update-status') return "Template de relocalisation d'ordinateur de masse";
    if (key === 'sku_begin_with') return 'Sku commence par';
    if (key === 'rate') return 'Taux';
    if (key === 'fixed_amount') return 'Montant fixe';
    if (key === 'accessory_problems') return 'Problèmes chargeurs';

    if (process.env.NODE_ENV !== 'production') throw new Error(`Unknown key ${key}/${alt} in DicoLabelsService`);
    return key;
  }

  decodeYearCode(value: string): string {
    if (!value) return;
    const [firstChar, ...year] = value.split('');
    switch (firstChar) {
      case 'E':
        return `Early 20${year.join('')}`;
      case 'M':
        return `Mid. 20${year.join('')}`;
      case 'L':
        return `Late 20${year.join('')}`;
      default:
        return `20${year.join('')}`;
    }
  }

  decodeYearOnly(value: string): string {
    if (!value) return;
    return `20${value.slice(1, 3)}`;
  }

  decodeColor(value: string, label: string): string {
    if (!value) return;
    switch (value) {
      case '0A':
        return '<i class="fas fa-circle" style="color:#e5e7ea"></i> ' + label;
      case '0B':
        return '<i class="far fa-circle"></i> ' + label;
      case 'BL':
        return '<i class="fas fa-circle" style="color:#25486a"></i> ' + label;
      case '0G':
        return '<i class="fas fa-circle" style="color:#a9a8ac"></i> ' + label;
      case '0J':
        return '<i class="fas fa-circle" style="color:#e88b1c"></i> ' + label;
      case '0N':
        return '<i class="fas fa-circle" style="color:#333"></i> ' + label;
      case '0O':
        return '<i class="fas fa-circle" style="color:#f3e2cb"></i> ' + label;
      case 'OR':
        return '<i class="fas fa-circle" style="color:#e76840"></i> ' + label;
      case '0R':
        return '<i class="fas fa-circle" style="color:#fcdad6"></i> ' + label;
      case 'Ro':
        return '<i class="fas fa-circle" style="color:#b72d31"></i> ' + label;
      case 'RO':
        return '<i class="fas fa-circle" style="color:#b72b2c"></i> ' + label;
      case '0V':
        return '<i class="fas fa-circle" style="color:#14505b"></i> ' + label;
      case 'VI':
        return '<i class="fas fa-circle" style="color:#343c70"></i> ' + label;
      case 'MN':
        return '<i class="fas fa-circle" style="color:#2E3641"></i> ' + label;
      case 'LS':
        return '<i class="fas fa-circle" style="color:#F0E5D3"></i> ' + label;
      case 'NS':
        return '<i class="fas fa-circle" style="color:#2E2C2F"></i> ' + label;
      default:
        return value;
    }
  }

  decodeFlag(value: string) {
    if (!value || value == 'AA') return;
    return (
      '<img src="/img/flags/' +
      value +
      '.png" style="width:1rem;height:1rem;margin-right:0.5rem;border-radius:1rem"></img>'
    );
  }

  decodeDeviceType(value: string): string {
    if (!value) return;
    switch (value) {
      case 'laptop':
        return 'Ordinateur portable';
      case 'desktop':
        return 'Ordinateur fixe';
      default:
        return value;
    }
  }

  decodeLocationType(value: string): string {
    if (!value) return;
    switch (value) {
      case 'stock':
        return 'Stock';
      case 'cart':
        return 'Chariot';
      case 'sav':
        return 'SAV';
      case 'preparation':
        return 'Préparation';
      case 'ri':
        return 'Réparation interne';
      case 'shipping':
        return 'Expédition';
      default:
        return value;
    }
  }

  decodeCompanyRole(value: UserCompanyRole): string {
    if (!value) return;
    switch (value) {
      case UserCompanyRole.ADMIN:
        return '<i class="fas fa-user-lock"></i> Administrateur';
      case UserCompanyRole.DIRECTION:
        return '<i class="fas fa-user"></i> Direction';
      case UserCompanyRole.TECHNICIEN:
        return '<i class="fas fa-user"></i> Technicien';
      default:
        return '-';
    }
  }

  decodeAppSubSection = (value: string): string => {
    if (!value) return;
    if (value === 'cleaning') return "Nettoyage de l'ordinateur";
    if (value === 'conformity') return 'Contrôle conformité';
    if (value === 'os') return "Installation de l'OS";
    if (value === 'packaging') return 'Emballage';
    if (value === 'parts') return 'Changement des pièces';
  };

  formatName(user: { first_name?: string; last_name?: string } = {}): string {
    const { first_name, last_name } = user;
    return (
      '' +
      (first_name ? capitalize(first_name) : '') +
      (last_name ? (first_name ? ' ' : '') + last_name.toUpperCase() : '') +
      (!first_name && !last_name ? 'Inconnu' : '')
    );
  }

  formatAmount(amont, currencies, currency): string {
    //const price = order?.price || order?.total_price;
    if (amont === null) return '-';

    const currency_label = (currencies || {})[currency] || '-';
    return `${amont} ${currency_label}`;
  }

  formatItemDesc(
    item: Device | Accessory | Battery | Charger | Consumable | HardDrive | Ram,
    settings: GlobalSettings
  ): string {
    if (!item || !settings) return '-';
    if ('desc' in item) return item.desc;
    const desc = [];
    desc.push('year' in item ? Object.fromEntries(settings.years)[item.year].display_name : '-');
    desc.push('proc' in item ? Object.fromEntries(settings.procs)[item.proc] : '-');
    desc.push('freq' in item ? `${item.freq} ${this.getUnit('freq')}` : '-');
    desc.push('graphic_card' in item ? Object.fromEntries(settings.graphic_cards)[item.graphic_card] : '-');
    desc.push('hdsize' in item ? `${item.hdsize} ${this.getUnit('hdsize')}` : '-');
    desc.push('hdtype' in item ? Object.fromEntries(settings.drives)[item.hdtype] : '-');
    desc.push('ram' in item ? `${item.ram} ${this.getUnit('ram')}` : '-');
    desc.push('color' in item ? Object.fromEntries(settings.colors)[item.color] : '-');
    desc.push('grade' in item ? Object.fromEntries(settings.grades)[item.grade] : '-');
    return desc.join(' / ');
  }

  formatPurchaseItemShare(items: PurchaseItem[], key: keyof PurchaseItem, labels?: string[][]) {
    const dicoLabels = labels
      ? labels.reduce((prev, curr) => {
          prev[curr[0]] = curr[1];
          return prev;
        }, {})
      : {};
    const values = items.reduce(
      (prev, curr) => {
        prev[curr[key]] = (prev[curr[key]] || 0) + 1;
        prev.total += 1;
        return prev;
      },
      { total: 0 } as Record<string | number, number>
    );
    return Object.keys(values)
      .filter(key => key !== 'total')
      .sort((a, b) => values[b] - values[a])
      .map(e => {
        if (['model', 'consumable_type', 'spare_type'].indexOf(key) >= 0) {
          return `${dicoLabels[e] || e} (${values[e]})`;
        } else {
          return `${dicoLabels[e] || e} (${Math.round((values[e] / values['total']) * 100)} %)`;
        }
      })
      .join(', ');
  }

  getUnit(key: string): string {
    if (key === 'amount_ht') return '€';
    if (key === 'amount_ttc') return '€';
    if (key === 'battery_ratio') return '%';
    if (key === 'freq') return 'GHz';
    if (key === 'hdsize') return 'Go';
    if (key === 'ram') return 'Go';
    if (key === 'mah') return 'mAh';
    if (key === 'new_battery_price') return '€';
    if (key === 'purchase_price') return '€';
    if (key === 'shipping_time') return 'jours';
    if (key === 'shipping_cost') return '€';
    if (key === 'shipping_fees') return '€';
    if (key === 'size') return '"';
    if (key === 'ordered_size') return '"';
    if (key === 'other_price') return '€';
    if (key === 'payment_delay') return 'jours';
    if (key === 'payment_scheduled_delay') return 'jours';
    if (key === 'price') return '€';
    if (key === 'upgrade_amount') return '€';
    if (key === 'warranty') return 'mois';
    if (key === 'warranty-supplier') return 'jours';
    if (key === 'warranty_amount') return '€';
  }
}

export const dicoLabelsService = new DicoLabelsService();
