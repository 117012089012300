import { isArray, isEmpty, isNull, omitBy } from 'lodash';
import { Module } from 'vuex';

import { collectionsService } from '@/services/collections/collections.service';
import { Collection, CollectionList, CollectionName } from '@/services/collections/collections.types';
import { formsService } from '@/services/forms/forms.service';
import { optionsService } from '@/services/options/options.service';
import { FilterOptions } from '@/services/options/options.types';
import { tablesService } from '@/services/tables/tables.service';
import { TablePaginationOptions } from '@/services/tables/tables.types';
import { RootState } from '@/store';

export interface TableState<T extends CollectionName> {
  table: T;
  list: CollectionList<T>;
  listLoading: boolean;
  exportLoading: boolean;

  /* In the UI, those filters are in the left-hand panel */
  panelFilters: FilterOptions<T>;
  panelFiltersOptions: FilterOptions<T>;
  panelFiltersOptionsLoading: boolean;

  previousFilters: FilterOptions<T>;

  /* In the UI, those filters are above the table */
  tabFilters: FilterOptions<T>;

  /* Those filters will not appear in the UI, but will be used to query data */
  hiddenFilters: FilterOptions<T>;

  /* The free text search */
  search: string;

  pagination: TablePaginationOptions;
}

const state: TableState<any> = {
  table: null,
  list: null,
  listLoading: false,
  exportLoading: false,
  panelFilters: {},
  panelFiltersOptions: null,
  panelFiltersOptionsLoading: false,
  previousFilters: null,
  tabFilters: {},
  hiddenFilters: {},
  search: null,
  pagination: {
    page: 1,
    limit: 50,
    sort_by: null,
    sort_dir: 'desc'
  }
};

const options: Module<TableState<any>, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    async setTable({ commit, dispatch }, table: CollectionName): Promise<void> {
      return dispatch('clearTable').then(() => {
        commit('table', table);
        return dispatch('listOptions');
      });
    },
    async clearTable({ commit, dispatch }): Promise<void> {
      return Promise.all([
        dispatch('clearPanelFilters'),
        dispatch('clearTabFilters'),
        dispatch('clearHiddenFilters'),
        dispatch('clearTextSearch'),
        dispatch('clearPagination')
      ]).then(() => {
        commit('table', null);
        commit('list', null);
        commit('listLoading', false);
      });
    },
    async listRows({ commit, dispatch, state, getters }): Promise<void> {
      if (!state.table) return;
      commit('listLoading', true);
      const collectionService = collectionsService.getCollectionService(state.table);
      /**
       * Creating a query string and saving it in the browser history to keep same URL when refreshing the page
       */
      /* We need to de-proxy the state before passing it to the navigation history */
      const routeHistState = JSON.parse(
        JSON.stringify(omitBy({ ...getters.queryOptions.filters, ...getters.queryOptions.pagination }, isNull))
      );
      if (!isEmpty(state.hiddenFilters)) routeHistState['hidden'] = Object.keys(state.hiddenFilters);
      if (!isEmpty(state.panelFilters)) routeHistState['panel'] = Object.keys(state.panelFilters);
      if (!isEmpty(state.tabFilters)) routeHistState['tab'] = Object.keys(state.tabFilters);
      const routeHistoryUrl = new URLSearchParams(routeHistState).toString();
      history.pushState(routeHistState, '', `?${routeHistoryUrl}`);

      return collectionService
        .list(getters.queryOptions)
        .then(list => list && commit('list', list))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('listLoading', false));
    },
    async exportTable({ commit, dispatch, getters }): Promise<void> {
      if (!state.table) return;
      commit('exportLoading', true);
      const collectionService = collectionsService.getCollectionService(state.table);

      return collectionService
        .list({ ...getters.queryOptions, options: { format: 'xlsx' } })
        .then(resp => dispatch('file/saveBase64As', resp, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('exportLoading', false));
    },
    async liveEditItem<T extends CollectionName>(
      { state, commit, dispatch },
      item: Partial<Collection<T>>
    ): Promise<void> {
      const collectionService = collectionsService.getCollectionService(state.table);
      return collectionService
        .update(item.id, item)
        .then(() => dispatch('listRows'))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('listLoading', false));
    },
    async listOptions({ commit, dispatch, state }): Promise<void> {
      commit('panelFiltersOptionsLoading', true);
      return optionsService
        .list(state.table, { ...state.tabFilters, ...state.hiddenFilters })
        .then(options => {
          commit('panelFiltersOptions', options);
        })
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('panelFiltersOptionsLoading', false));
    },
    async setPage({ commit, state, dispatch }, page: number): Promise<void> {
      commit('pagination', { ...state.pagination, page });
      return dispatch('listRows');
    },
    async panelFilterBy<T extends CollectionName>({ commit, dispatch }, values: FilterOptions<T> = {}): Promise<void> {
      const filters = omitBy(
        values,
        (value: string[]) => isArray(value) && (!value.length || value.indexOf('all') > -1)
      );
      commit('panelFilters', filters);
      return dispatch('setPage', 1);
    },
    async goToTable({ commit, dispatch }, payload: [string, Record<string, unknown>]): Promise<void> {
      dispatch('clearPanelFilters'),
        dispatch('clearTable').then(() => {
          commit('table', payload[0]), commit('panelFilters', payload[1]);
          dispatch('setPage', 1).then(() => dispatch('listOptions'));
        });
    },
    async saveFilters({ commit }, filters: Record<string, unknown>): Promise<void> {
      commit('previousFilters', filters);
    },
    async tabFilterBy<T extends CollectionName>(
      { state, commit, dispatch },
      values: FilterOptions<T> = {}
    ): Promise<void> {
      commit('tabFilters', { ...state.tabFilters, ...values });
      return dispatch('listOptions').then(() => dispatch('setPage', 1));
    },
    async hiddenFilterBy<T extends CollectionName>({ commit }, values: FilterOptions<T> = {}): Promise<void> {
      commit('hiddenFilters', values);
    },
    async searchBy({ commit, dispatch }, search: string): Promise<void> {
      commit('search', search ? search : null);
      return dispatch('setPage', 1);
    },
    async sortBy<T extends CollectionName>({ commit, state, dispatch }, key: keyof Collection<T>): Promise<void> {
      const pagination = state.pagination;
      if (pagination.sort_by !== key) commit('pagination', { ...pagination, sort_by: key });
      else commit('pagination', { ...pagination, sort_dir: pagination.sort_dir === 'desc' ? 'asc' : 'desc' });
      return dispatch('setPage', 1);
    },
    async parseQueryString({ commit, dispatch }, query: Record<string, string>): Promise<void> {
      ['tab', 'hidden', 'panel'].forEach(prefix => {
        if (!query[prefix]) return;
        const filters = query[prefix].split(',').reduce((prev, curr) => {
          const filterValue = query[curr] && query[curr].split(',');
          if (filterValue && filterValue.length > 1) {
            prev[curr] = filterValue.map(value => optionsService.parseOptionValue(value));
          } else if (filterValue) prev[curr] = optionsService.parseOptionValue(filterValue[0]);
          return prev;
        }, {});
        commit(`${prefix}Filters`, filters);
      });
      if (query.fulltext) commit('search', query.fulltext);
      if (query.page) commit('pagination', { ...state.pagination, page: +query.page });
      if (query.sort_by) commit('pagination', { ...state.pagination, sort_by: query.sort_by });
      if (query.sort_dir) commit('pagination', { ...state.pagination, sort_dir: query.sort_dir });
      return dispatch('listOptions').then(() => dispatch('listRows'));
    },
    async resetFilters({ commit, dispatch }): Promise<void> {
      commit('panelFilters', {});
      return dispatch('listOptions').then(() => dispatch('setPage', 1));
    },
    async clearPanelFilters({ commit }): Promise<void> {
      commit('panelFilters', {});
      commit('panelFiltersOptions', null);
      commit('panelFiltersOptionsLoading', false);
    },
    async clearPreviousFilters({ commit }): Promise<void> {
      commit('previousFilters', {});
    },
    async clearTabFilters({ commit }): Promise<void> {
      commit('tabFilters', {});
    },
    async clearHiddenFilters({ commit }): Promise<void> {
      commit('hiddenFilters', {});
    },
    async clearTextSearch({ commit }): Promise<void> {
      commit('search', null);
    },
    async clearPagination({ commit }): Promise<void> {
      commit('pagination', {
        page: 1,
        limit: 50,
        sort_by: null,
        sort_dir: 'desc'
      });
    }
  },
  mutations: {
    table: (state, table) => (state.table = table),
    list: (state, list) => (state.list = list),
    listLoading: (state, loading) => (state.listLoading = loading),
    exportLoading: (state, loading) => (state.exportLoading = loading),
    panelFilters: (state, panelFilters) => (state.panelFilters = panelFilters),
    panelFiltersOptions: (state, opts) => (state.panelFiltersOptions = opts),
    panelFiltersOptionsLoading: (state, loading) => (state.panelFiltersOptionsLoading = loading),
    previousFilters: (state, previousFilters) => (state.previousFilters = previousFilters),
    tabFilters: (state, tabFilters) => (state.tabFilters = tabFilters),
    hiddenFilters: (state, hiddenFilters) => (state.hiddenFilters = hiddenFilters),
    search: (state, text) => (state.search = text),
    pagination: (state, options) => (state.pagination = options)
  },
  getters: {
    queryOptions: state => ({
      filters: { ...state.hiddenFilters, ...state.panelFilters, ...state.tabFilters, fulltext: state.search },
      pagination: state.pagination
    }),
    rows: state => {
      if (!state.list) return [];
      else return state.list.values || [];
    },
    rowsCount: state => {
      if (!state.list) return 0;
      else return state.list.count || 0;
    },
    columns: (state, getters, rootState) => {
      return tablesService.listTableColumns(state.table, rootState['global-settings'].settings, state.tabFilters);
    },
    sortKeys: state => {
      return tablesService.listSortingKeys(state.table);
    },
    searchPlaceholder: state => {
      if (state.table === 'devices') return 'Modèle, SKU, ...';
      else if (state.table === 'orders') return 'Client, marketplace, ...';
      else if (state.table === 'order-lines') return 'Client, marketplace, ...';
      else return 'Recherche...';
    },
    panelFiltersForm: (state, getters, rootState) =>
      formsService.getFiltersForm(state.panelFilters, state.panelFiltersOptions, rootState['global-settings'].settings)
  }
};

export default options;
