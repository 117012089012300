import axios from '@/core/axios';

import { CollectionList } from '../collections/collections.types';
import {
  OrderLine,
  OrderLinesCarrierLabelData,
  OrderLinesListOptions,
  OrderPreparationLabelData,
  OrdersImportResponse
} from './order-lines.types';

export class OrderLinesService {
  list(opts: OrderLinesListOptions = {}): Promise<CollectionList<'order-lines'>> {
    const { filters, pagination } = opts;

    return axios
      .get<CollectionList<'order-lines'>>(`/order-lines`, { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }

  get(orderLineId: number): Promise<OrderLine> {
    return axios.get<OrderLine>(`/order-lines/${orderLineId}`).then(resp => resp?.data);
  }

  newOrderLine(): Promise<OrderLine> {
    return axios.get<OrderLine>(`order-lines/new`).then(resp => resp?.data);
  }

  create(order_line: OrderLine): Promise<OrderLine> {
    return axios.post<OrderLine>(`order-lines`, { order_line }).then(resp => resp?.data);
  }

  update(orderLineId: number | string, order_line: Partial<OrderLine>): Promise<OrderLine> {
    if (!orderLineId) throw new Error('Missing order id in update');
    return axios.put<OrderLine>(`order-lines/${orderLineId}`, { order_line }).then(resp => resp?.data);
  }

  delete(orderLineId: number): Promise<void> {
    if (!orderLineId) throw new Error('Missing order id in delete');
    return axios.delete<void>(`/order-lines/${orderLineId}`).then(resp => resp?.data);
  }

  resetCarrierLabel(orderLineId: number): Promise<OrderLine> {
    if (!orderLineId) throw new Error('Missing orderLine id in reset_carrier_label');

    return axios.get<OrderLine>(`/order-lines/${orderLineId}/reset_carrier_label`).then(resp => resp?.data);
  }

  addAssociationError(payload: any): Promise<void> {
    return axios.post<void>('/order-lines/association-errors/' + payload.order, payload).then(resp => resp?.data);
  }

  getCarrierLabel(orderLineId: number): Promise<OrderLinesCarrierLabelData> {
    return axios.get<OrderLinesCarrierLabelData>(`/order-lines/${orderLineId}/carrier-label`).then(resp => resp?.data);
  }

  getPreparationLabel(orderLineId: number): Promise<OrderPreparationLabelData> {
    return axios
      .get<OrderPreparationLabelData>(`/order-lines/${orderLineId}/preparation-label`)
      .then(resp => resp?.data);
  }

  importOrders(marketplace: string, file: File): Promise<OrdersImportResponse> {
    const formData = new FormData();
    formData.append('marketplace', marketplace);
    formData.append('file', file);
    return axios
      .post<OrdersImportResponse>('/order-lines/import', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  duplicate(orderLineId: string, nTimes: number): Promise<void> {
    return axios.post<void>(`/order-lines/${orderLineId}/duplicate`, { n_times: nTimes || 1 }).then(resp => resp?.data);
  }
}

export const orderLinesService = new OrderLinesService();
