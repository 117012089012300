import axios from '@/core/axios';
import { omitBy } from 'lodash';

import {
  PurchasesListOptions,
  Purchase,
  PurchasePauseOptions,
  PurchasePaymentScheduleOptions,
  PurchasePaymentEffectiveOptions,
  PurchaseRMACreationOptions,
  PurchaseItemsFix
} from './purchases.types';
import { CollectionList } from '../collections/collections.types';

export class PurchasesService {
  list(opts: PurchasesListOptions = {}): Promise<CollectionList<'purchases'>> {
    const { filters, pagination, options } = opts;

    return axios
      .get<CollectionList<'purchases'>>(`/purchases`, {
        params: { ...filters, ...pagination, ...options }
      })
      .then(resp => resp?.data);
  }

  get(purchaseId: number): Promise<Purchase> {
    return axios.get<Purchase>(`/purchases/${purchaseId}`).then(resp => resp?.data);
  }

  new(): Promise<Purchase> {
    return axios.get<Purchase>(`/purchases/new`).then(resp => resp?.data);
  }

  create(purchase: Purchase): Promise<Purchase> {
    return axios.post<Purchase>(`/purchases`, { purchase }).then(resp => resp?.data);
  }

  update(purchaseId: number | string, purchase: Partial<Purchase>): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in update');

    /**
     * We remove keys that are files (files are updated with another function)
     */

    const purchaseWithoutFiles = omitBy(purchase, (value, key) => /_file$/.test(key));

    return axios.put<Purchase>(`purchases/${purchaseId}`, { purchase: purchaseWithoutFiles }).then(resp => resp?.data);
  }

  updateFiles(purchaseId: number | string, files: Record<string, File>): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in updateFiles');

    const formData = Object.entries(files).reduce((prev, curr) => {
      prev.append(`purchase[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .put<Purchase>(`purchases/${purchaseId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(resp => ('analysis_supplier_file' in files ? this.analyze(purchaseId) : resp?.data));
  }

  fixAnalysisSupplierFile(purchaseId: number | string, files: Record<string, File>): Promise<PurchaseItemsFix> {
    if (!purchaseId) throw new Error('Missing purchase id in updateFiles');

    const formData = Object.entries(files).reduce((prev, curr) => {
      prev.append(`[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .post(`purchases/${purchaseId}/fix_analysis_supplier_file`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  pause(purchaseId: number | string, params: PurchasePauseOptions, pause: boolean): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in pause');

    return axios
      .post<Purchase>(`purchases/${purchaseId}/${pause ? 'pause' : 'depause'}`, { params })
      .then(resp => resp?.data);
  }

  analyze(purchaseId: number | string): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in analyze');

    return axios.post<Purchase>(`purchases/${purchaseId}/analyze`).then(resp => resp?.data);
  }

  schedule(purchaseId: number | string, params: PurchasePaymentScheduleOptions): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in schedule');

    return axios.post<Purchase>(`purchases/${purchaseId}/schedule`, { params }).then(resp => resp?.data);
  }

  createRMA(purchaseId: number | string, params: PurchaseRMACreationOptions): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in createRMA');

    return axios.post<Purchase>(`purchases/${purchaseId}/create-rma`, { params }).then(resp => resp?.data);
  }

  pay(purchaseId: number | string, params: PurchasePaymentEffectiveOptions): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in pay');

    return axios.post<Purchase>(`purchases/${purchaseId}/pay`, { params }).then(resp => resp?.data);
  }

  control(purchaseId: number | string): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in control');

    return axios.post<Purchase>(`purchases/${purchaseId}/control`).then(resp => resp?.data);
  }

  validate(purchaseId: number | string): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in validate');

    return axios.post<Purchase>(`purchases/${purchaseId}/validate`).then(resp => resp?.data);
  }

  delete(purchaseId: number): Promise<void> {
    if (!purchaseId) throw new Error('Missing purchase id in delete');
    return axios.delete<void>(`/purchases/${purchaseId}`).then(resp => resp?.data);
  }

  exportDevices(purchaseId: number | string): Promise<void> {
    if (!purchaseId) throw new Error('Missing purchase id in delete');
    return axios.get<void>(`/purchases/${purchaseId}/export_devices`).then(resp => resp?.data);
  }
}

export const purchasesService = new PurchasesService();
