import { Module } from 'vuex';

import { gradesService } from '@/services/grades/grades.service';
import { Grade } from '@/services/grades/grades.types';
import { RootState } from '@/store';

export interface GradesState {
  grade: Grade;
  gradeLoading: boolean;
}

const state: GradesState = {
  grade: null,
  gradeLoading: false
};

const options: Module<GradesState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    get({ commit, dispatch }, serialNo: string) {
      commit('gradeLoading', true);
      return gradesService
        .get(serialNo)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    createGrade: ({ commit, dispatch }, serial_no: string): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .create(serial_no)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    editStateGrade({ commit }, options: { currentStepCode: string; value }) {
      commit('gradeLoading', true);
      const { currentStepCode, value } = options;
      if (value.fieldLabel)
        state.grade[currentStepCode]['fields'][value.fieldBlockLabel][value.fieldLabel] = value.value;
      else {
        state.grade[currentStepCode]['fields'][value.fieldBlockLabel] = value.value;
      }
      commit('gradeLoading', false);
    },
    updateGrade({ commit, dispatch }, options: { grade_id: number; data: any }) {
      const { grade_id, data } = options;
      commit('gradeLoading', true);
      return gradesService
        .update(grade_id, data)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    }
  },
  mutations: {
    grade: (state, grade: Grade) => (state.grade = grade),
    gradeLoading: (state, loading: boolean) => (state.gradeLoading = loading)
  }
};

export default options;
