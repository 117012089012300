import { Module } from 'vuex';

import { Collection, CollectionName } from '@/services/collections/collections.types';
import { MatchError } from '@/services/match-errors/match-errors.types';
import { RootState } from '@/store';

export interface FocusState {
  modal:
    | 'CageInfosModal'
    | 'CommissionInfoModal'
    | 'DeviceInfosModal'
    | 'MatchInfosModal'
    | 'OrderInfosModal'
    | 'OrderLineInfosModal'
    | 'OrderReturnInfosModal'
    | 'PickingInfosModal'
    | 'ProductionOrderInfosModal';
}

const state: FocusState = {
  modal: null
};

const options: Module<FocusState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setFocus<T extends CollectionName>(
      { dispatch, commit },
      {
        table,
        item,
        initTab,
        onClose
      }: {
        table: T;
        item: Collection<T> | string | number;
        initTab?: string;
        onClose: (isConfirmed?: boolean) => Promise<any>;
      }
    ): Promise<void> {
      return dispatch('clearFocus')
        .then(() => {
          /**
           * For the modal name, we cannot use a simple getter based on table, because when table === 'devices',
           * sometimes we use the Device modal and sometimes we use the Match modal (when it does have a Match).
           */
          if (table === 'devices') {
            commit('modal', 'DeviceInfosModal');
            return item && dispatch('device/setDevice', item, { root: true });
          } else if (table === 'orders') {
            commit('modal', 'OrderInfosModal');
            return item && dispatch('order/setOrder', item, { root: true });
          } else if (table === 'order-lines') {
            commit('modal', 'OrderLineInfosModal');
            return item && dispatch('order-line/setOrderLine', item, { root: true });
          } else if (table === 'order-returns') {
            commit('modal', 'OrderReturnInfosModal');
            return item && dispatch('order-return/setOrderReturn', item, { root: true });
          } else if (table === 'production-orders') {
            commit('modal', 'ProductionOrderInfosModal');
            return item && dispatch('production-order/setProductionOrder', item, { root: true });
          } else if (table === 'pickings') {
            commit('modal', 'PickingInfosModal');
            return item && dispatch('picking/setPicking', item, { root: true });
          } else if (table === 'matches') {
            commit('modal', 'MatchInfosModal');
            return item && dispatch('match/setMatch', item, { root: true });
          } else if (table === 'match-errors') {
            /* We use the same modal as for the Match, so we need to use the match id so set the Match store */
            commit('modal', 'MatchInfosModal');
            const matchId = (item as MatchError).match.id;
            return item && dispatch('match/setMatch', matchId, { root: true });
          } else if (table === 'dispatch-cages') {
            commit('modal', 'CageInfosModal');
            return item && dispatch('cage/setCage', item, { root: true });
          } else if (table === 'commissions') {
            commit('modal', 'CommissionInfosModal');
            return item && dispatch('commission/setCommission', item, { root: true });
          } else if (table === 'purchase-queries') {
            commit('modal', 'PurchaseQueryInfosModal');
            return item && dispatch('purchase-query/setQuery', item, { root: true });
          } else if (table === 'suppliers') {
            commit('modal', 'SupplierEditionModal');
            return item && dispatch('supplier/setSupplier', item, { root: true });
          }
        })
        .then(() => {
          dispatch('modal/showModal', { component: state.modal, ...(initTab && { initTab }), onClose }, { root: true });
        });
    },
    setCreationFocus<T extends CollectionName>(
      { dispatch, commit },
      { table, data, onClose }: { table: T; data: T | number; onClose: (isConfirmed?: boolean) => Promise<any> }
    ): Promise<void> {
      return dispatch('clearFocus')
        .then(() => {
          /**
           * If we ever need to create other types or resources, we should follow the same strategy as Order example
           */
          if (table === 'orders') {
            commit('modal', 'OrderCreationModal');
            return dispatch('order/newOrder', null, { root: true });
          } else if (table === 'order-lines') {
            commit('modal', 'OrderLineCreationModal');
            return data && dispatch('order-line/newOrderLine', data, { root: true });
          } else if (table === 'production-orders') {
            commit('modal', 'ProductionOrderCreationModal');
            return dispatch('production-order/newProductionOrder', null, { root: true });
          } else if (table === 'pickings') {
            commit('modal', 'PickingCreationModal');
            return dispatch('picking/newPicking', null, { root: true });
          } else if (table === 'devices') {
            commit('modal', 'DeviceCreationModal');
            return dispatch('device/newDevice', null, { root: true });
          } else if (table === 'purchase-queries') {
            commit('modal', 'PurchaseQueryCreationModal');
            return dispatch('purchase-query/newQuery', null, { root: true });
          } else if (table === 'suppliers') {
            commit('modal', 'SupplierEditionModal');
            return dispatch('supplier/newSupplier', null, { root: true });
          } else if (table === 'dispatch-cages') {
            commit('modal', 'CageCreationModal');
            return dispatch('cage/newCage', null, { root: true });
          } else if (table === 'commissions') {
            commit('modal', 'CommissionCreationModal');
            return dispatch('commission/newCommission', null, { root: true });
          }
        })
        .then(() => dispatch('modal/showModal', { component: state.modal, onClose }, { root: true }));
    },
    clearFocus({ state, commit, dispatch }): Promise<void> {
      if (!state.modal) return;
      return dispatch('modal/hideModal', null, { root: true })
        .then(() => {
          if (state.modal === 'DeviceInfosModal') return dispatch('device/setDevice', null, { root: true });
          else if (state.modal === 'OrderInfosModal') return dispatch('order/setOrder', null, { root: true });
          else if (state.modal === 'OrderLineInfosModal')
            return dispatch('order-line/setOrderLine', null, { root: true });
          else if (state.modal === 'ProductionOrderInfosModal')
            return dispatch('production-order/setProductionOrder', null, { root: true });
          else if (state.modal === 'PickingInfosModal') return dispatch('picking/setPicking', null, { root: true });
          else if (state.modal === 'MatchInfosModal') return dispatch('match/setMatch', null, { root: true });
          else if (state.modal === 'CageInfosModal') return dispatch('cage/setCage', null, { root: true });
        })
        .then(() => commit('modal', null));
    }
  },
  mutations: {
    modal: (state, modal) => (state.modal = modal)
  }
};

export default options;
