import { Module } from 'vuex';

import { formsService } from '@/services/forms/forms.service';
import { orderLinesService } from '@/services/order-lines/order-lines.service';
import { OrderLine, OrderLineDuplicationData } from '@/services/order-lines/order-lines.types';
import { ordersService } from '@/services/orders/orders.service';
import { RootState } from '@/store';

export interface OrderLineState {
  orderLine: OrderLine;
  orderLineLoading: boolean;
  carrierLabelLoading: boolean;
  preparationLabelLoading: boolean;
  invoiceLoading: boolean;
  ordersImporting: boolean;
}

const state: OrderLineState = {
  orderLine: null,
  orderLineLoading: false,
  carrierLabelLoading: false,
  preparationLabelLoading: false,
  invoiceLoading: false,
  ordersImporting: false
};

const options: Module<OrderLineState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setOrderLine: ({ commit, dispatch }, orderLine: OrderLine | string | number): Promise<void> => {
      if (typeof orderLine === 'number' || typeof orderLine === 'string') return dispatch('getOrderLine', orderLine);
      else commit('orderLine', orderLine);
    },
    newOrderLine: ({ commit, dispatch }, orderId: number): Promise<void> => {
      commit('orderLine', null);
      commit('orderLineLoading', true);
      return orderLinesService
        .newOrderLine()
        .then(orderLine => {
          if (orderLine != null) {
            orderLine.order_id = orderId;
            orderLine && commit('orderLine', orderLine);
          }
        })
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLineLoading', false));
    },
    createOrderLine: ({ commit, dispatch }, orderLine: OrderLine): Promise<void> => {
      commit('orderLineLoading', true);
      return orderLinesService
        .create(orderLine)
        .then(orderLine => orderLine && commit('orderLine', orderLine))
        .then(() => dispatch('alert/pushSuccess', 'Commande créée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLineLoading', false));
    },
    updateOrderLine: async ({ commit, dispatch }, orderLine: OrderLine): Promise<void> => {
      commit('orderLineLoading', true);
      try {
        await orderLinesService.update(orderLine.id, orderLine);
        if (orderLine.order !== undefined) {
          await ordersService.update(orderLine.order.id, orderLine);
        }
        dispatch('setOrderLine', orderLine.id);
        dispatch('alert/pushSuccess', 'Commande/ligne de commande mise à jour !', { root: true });
      } catch (error) {
        dispatch('alert/pushError', error, { root: true });
      } finally {
        commit('orderLineLoading', false);
      }
    },
    deleteOrderLine: ({ commit, dispatch }, orderLineId: number): Promise<void> => {
      commit('orderLineLoading', true);
      return orderLinesService
        .delete(orderLineId)
        .then(() => commit('order-line', null))
        .then(() => dispatch('alert/pushSuccess', 'Ligne de commande supprimée !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLineLoading', false));
    },
    resetCarrierLabel: ({ commit, dispatch }, orderLineId: number): Promise<void> => {
      commit('orderLineLoading', true);
      return orderLinesService
        .resetCarrierLabel(orderLineId)
        .then(orderLine => orderLine && commit('orderLine', orderLine))
        .then(() => dispatch('alert/pushSuccess', 'Reset du BL effectué', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLineLoading', false));
    },
    getOrderLine: ({ commit, dispatch }, orderLineId: number): Promise<void> => {
      commit('orderLineLoading', true);
      return orderLinesService
        .get(orderLineId)
        .then(orderLine => orderLine && commit('orderLine', orderLine))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLineLoading', false));
    },
    duplicateOrderLine: ({ commit, dispatch }, options: OrderLineDuplicationData): Promise<void> => {
      commit('orderLineLoading', true);
      return orderLinesService
        .duplicate(options.order_line_id, options.n_times)
        .then(() => dispatch('alert/pushSuccess', `Produit dupliqué ${options.n_times} fois !`, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('orderLineLoading', false));
    },
    createExchangeOrderLine: ({ commit, dispatch, getters }, orderLine: OrderLine): Promise<void> => {
      commit('orderLine', orderLine);
      return dispatch('createOrderLine', getters.orderLineExchangeCreationForm.value);
    },
    printCarrierLabel: ({ commit, dispatch }, orderLineId: number): Promise<void> => {
      commit('carrierLabelLoading', true);
      return orderLinesService
        .getCarrierLabel(orderLineId)
        .then(resp =>
          Promise.all([
            resp?.zpl && dispatch('printer/printWith', { data: [resp.zpl], printer: 'SHIPPING' }, { root: true }),
            dispatch('setOrderLine', orderLineId), // Reloading focuses order
            dispatch('alert/pushInfo', 'Impression du bon de transport...', { root: true })
          ])
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('carrierLabelLoading', false));
    },
    printPreparationLabel: ({ commit, dispatch }, orderId: number): Promise<void> => {
      commit('preparationLabelLoading', true);
      return orderLinesService
        .getPreparationLabel(orderId)
        .then(
          resp => resp?.zpl && dispatch('printer/printWith', { data: [resp.zpl], printer: 'PREPA' }, { root: true })
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('preparationLabelLoading', false));
    },
    importOrders: ({ commit, dispatch }, { marketplace, file }: { marketplace: string; file: File }): Promise<void> => {
      commit('ordersImporting', true);
      return orderLinesService
        .importOrders(marketplace, file)
        .then(resp =>
          dispatch(
            'alert/pushSuccess',
            `${resp.count} commande${resp.count > 1 ? 's' : ''} importée${resp.count > 1 ? 's' : ''} !`,
            { root: true }
          )
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('ordersImporting', false));
    }
  },
  mutations: {
    orderLine: (state, orderLine) => (state.orderLine = orderLine),
    orderLineLoading: (state, loading) => (state.orderLineLoading = loading),
    ordersImporting: (state, importing) => (state.ordersImporting = importing),
    carrierLabelLoading: (state, loading) => (state.carrierLabelLoading = loading),
    preparationLabelLoading: (state, loading) => (state.preparationLabelLoading = loading),
    invoiceLoading: (state, loading) => (state.invoiceLoading = loading)
  },
  getters: {
    orderLineCreationForm: (state, getters, rootState) =>
      formsService.getForm('order-line-creation', state.orderLine, rootState['global-settings'].settings),
    orderLineExchangeCreationForm: (state, getters, rootState) =>
      formsService.getForm('order-line-exchange-creation', state.orderLine, rootState['global-settings'].settings),
    orderLineForm: (state, getters, rootState, rootGetters) =>
      formsService.getForm('order-line', state.orderLine, rootState['global-settings'].settings, {
        UserCanUpdateOrderShippingDate: rootGetters['user/canUpdateOrderShippingDate']
      })
  }
};

export default options;
