import { devicesService } from '@/services/devices/devices.service';
import { marketplaceSkusService } from '@/services/marketplace-skus/marketplace-skus.service';
import { matchErrorsService } from '@/services/match-errors/match-errors.service';
import { matchesService } from '@/services/matches/matches.service';
import { orderLinesService } from '@/services/order-lines/order-lines.service';
import { orderReturnsService } from '@/services/order-returns/order-returns.service';
import { ordersService } from '@/services/orders/orders.service';
import { pickingsService } from '@/services/pickings/pickings.service';
import { productionOrdersService } from '@/services/production-orders/production-orders.service';
import { cagesService } from '@/services/cages/cages.service';
import { sparesService } from '@/services/spares/spares.service';
import { consumablesService } from '../consumables/consumables.service';
import { commissionsService } from '../commissions/commissions.service';
import { macStocksService } from '../mac-stocks/mac-stocks.service';
import { purchaseQueriesService } from '../purchase-queries/purchase-queries.service';
import { purchasesService } from '../purchases/purchases.service';
import { suppliersService } from '../suppliers/suppliers.service';
import { CollectionName, CollectionService } from './collections.types';

class CollectionsService {
  getCollectionService<T extends CollectionName>(collection: CollectionName): CollectionService<T> {
    // if (collection == 'commissions') return commissionsService as CollectionService<T>;
    // else if (collection === 'devices') return devicesService as CollectionService<T>;
    // else if (collection === 'orders') return ordersService as CollectionService<T>;
    // else if (collection === 'order-lines') return orderLinesService as CollectionService<T>;
    // else if (collection === 'order-returns') return orderReturnsService as CollectionService<T>;
    // else if (collection === 'production-orders') return productionOrdersService as CollectionService<T>;
    // else if (collection === 'matches') return matchesService as CollectionService<T>;
    // else if (collection === 'match-errors') return matchErrorsService as CollectionService<T>;
    // else if (collection === 'dispatch-cages') return cagesService as CollectionService<T>;
    // else if (collection === 'spares') return sparesService as CollectionService<T>;
    // else if (collection === 'consumables') return consumablesService as CollectionService<T>;
    // else if (collection === 'purchase-queries') return purchaseQueriesService as CollectionService<T>;
    // else if (collection === 'mac-stocks') return macStocksService as CollectionService<T>;
    // else if (collection === 'purchases') return purchasesService as CollectionService<T>;
    // else if (collection === 'suppliers') return suppliersService as CollectionService<T>;
    // else if (collection === 'pickings') return pickingsService as CollectionService<T>;
    // else if (collection === 'marketplace-skus') return marketplaceSkusService as CollectionService<T>;
    // else throw new Error('Unknown collection');
    switch (collection) {
      case 'commissions':
        return commissionsService as CollectionService<T>;
      case 'consumables':
        return consumablesService as CollectionService<T>;
      case 'devices':
        return devicesService as CollectionService<T>;
      case 'dispatch-cages':
        return cagesService as CollectionService<T>;
      case 'mac-stocks':
        return macStocksService as CollectionService<T>;
      case 'marketplace-skus':
        return marketplaceSkusService as CollectionService<T>;
      case 'matches':
        return matchesService as CollectionService<T>;
      case 'match-errors':
        return matchErrorsService as CollectionService<T>;
      case 'orders':
        return ordersService as CollectionService<T>;
      case 'order-lines':
        return orderLinesService as CollectionService<T>;
      case 'order-returns':
        return orderReturnsService as CollectionService<T>;
      case 'pickings':
        return pickingsService as CollectionService<T>;
      case 'production-orders':
        return productionOrdersService as CollectionService<T>;
      case 'purchases':
        return purchasesService as CollectionService<T>;
      case 'purchase-queries':
        return purchaseQueriesService as CollectionService<T>;
      case 'spares':
        return sparesService as CollectionService<T>;
      case 'suppliers':
        return suppliersService as CollectionService<T>;
      default:
        throw new Error('Unknown collection');
    }
  }
}

export const collectionsService = new CollectionsService();
