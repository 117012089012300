import { Module } from 'vuex';

import { RootState } from '@/store';
import { User, UserRoleType, UserDivision, ChangePasswordOptions } from '@/services/users/users.types';
import { usersService } from '@/services/users/users.service';
import { dicoLabelsService } from '@/services/dico-labels/dico-labels.service';
import { checkUserAccess } from '@/utils/users/check-user-access';

export interface UserState {
  authenticatedUser: User;
  authenticatedUserLoading: boolean;
  user: User;
  userLoading: boolean;
}

const state: UserState = {
  authenticatedUser: null,
  authenticatedUserLoading: false,
  user: null,
  userLoading: false
};

const options: Module<UserState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    getAuthenticatedUser: ({ commit, dispatch }): Promise<void> => {
      commit('authenticatedUserLoading', true);
      return usersService
        .getAuthenticatedUser()
        .then(user => user && commit('authenticatedUser', user))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('authenticatedUserLoading', false));
    },
    setUser: ({ commit, dispatch }, user: User | string | number): Promise<void> => {
      if (typeof user === 'number' || typeof user === 'string') return dispatch('getUser', user);
      else commit('user', user);
    },
    getUser: ({ commit, dispatch }, userId: number): Promise<void> => {
      commit('user', null);
      commit('userLoading', true);
      return usersService
        .get(userId)
        .then(user => user && commit('user', user))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('userLoading', false));
    },
    changePassword: ({ dispatch }, params: ChangePasswordOptions): Promise<void> => {
      return usersService
        .changePassword(state.authenticatedUser.id, params)
        .then(() => dispatch('alert/pushSuccess', 'Le mot de passe a bien été modifié', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }));
    }
  },
  mutations: {
    authenticatedUser: (state, user) => (state.authenticatedUser = user),
    authenticatedUserLoading: (state, loading) => (state.authenticatedUserLoading = loading),
    user: (state, user) => (state.user = user),
    userLoading: (state, loading) => (state.userLoading = loading)
  },
  getters: {
    authenticatedUserName: state => state.authenticatedUser && dicoLabelsService.formatName(state.authenticatedUser),
    isAdmin: state => checkUserAccess(state.authenticatedUser, [UserRoleType.ADMIN]),
    canBookedB2B: state =>
      checkUserAccess(state.authenticatedUser, [
        UserRoleType.B2B_MEMBER,
        UserRoleType.TECHNICAL_ALL_MANAGER,
        UserRoleType.ASSOCIATION_MEMBER
      ]),
    canDeleteDevice: state => checkUserAccess(state.authenticatedUser, [UserRoleType.DEVICE_DELETER]),
    canDeleteOrder: state => checkUserAccess(state.authenticatedUser, [UserRoleType.ORDER_DELETER]),
    canResetCarrierLabel: state => checkUserAccess(state.authenticatedUser, [UserRoleType.RESET_CARRIER_LABEL]),
    canPrintCarrierLabel: state => checkUserAccess(state.authenticatedUser, [UserRoleType.CARRIER_LABEL_PRINTER]),
    canDeclareOrderReturn: state =>
      checkUserAccess(state.authenticatedUser, [
        UserRoleType.SAV_MEMBER,
        UserRoleType.ADV_MEMBER,
        UserRoleType.TECHNICAL_ALL_MANAGER,
        UserRoleType.B2B_MEMBER,
        UserRoleType.RC_MEMBER
      ]),
    canValidateOrderReturnPayment: state => checkUserAccess(state.authenticatedUser, [UserRoleType.RC_MEMBER]),
    canUnmatch: state => checkUserAccess(state.authenticatedUser, [UserRoleType.UNMATCHER]),
    canUpdateOrderShippingDate: state =>
      checkUserAccess(state.authenticatedUser, [UserRoleType.ORDER_SHIPPING_DATE_UPDATER]),
    canAccessAdmin: state =>
      checkUserAccess(state.authenticatedUser, [UserRoleType.DATABASE_ADMIN, UserRoleType.EXPORTS_ADMIN]),
    canAccessAdminDatabase: state => checkUserAccess(state.authenticatedUser, [UserRoleType.DATABASE_ADMIN]),
    canAccessAdminExports: state => checkUserAccess(state.authenticatedUser, [UserRoleType.EXPORTS_ADMIN]),
    canAccessAdminMassStatusChange: state =>
      checkUserAccess(state.authenticatedUser, [UserRoleType.MASS_STATUS_CHANGE]),
    canAccessControl: state => {
      return (
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_SCANNER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_SCANNER], {
          division: UserDivision.CONTROL
        }) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_MANAGER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_MANAGER], {
          division: UserDivision.CONTROL
        })
      );
    },
    canAccessMatching: state => checkUserAccess(state.authenticatedUser, [UserRoleType.ASSOCIATION_MEMBER]),
    canListMatchingErrors: state =>
      checkUserAccess(state.authenticatedUser, [
        UserRoleType.TECHNICAL_ALL_MANAGER,
        UserRoleType.TECHNICAL_SELF_MANAGER
      ]),
    canAccessPrepare: state => {
      return (
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_SCANNER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_SCANNER], {
          division: UserDivision.PREPARATION
        }) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_MANAGER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_MANAGER], {
          division: UserDivision.PREPARATION
        })
      );
    },
    isPreparationMaster: state => checkUserAccess(state.authenticatedUser, [UserRoleType.PREPARATION_MASTER]),
    canAccessDispatch: state => {
      return (
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_SCANNER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_SCANNER], {
          division: UserDivision.DISPATCH
        }) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_MANAGER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_MANAGER], {
          division: UserDivision.DISPATCH
        })
      );
    },
    isShippingMaster: state => checkUserAccess(state.authenticatedUser, [UserRoleType.SHIPPING_MASTER]),
    canAccessAchats: state => checkUserAccess(state.authenticatedUser, [UserRoleType.ACHATS_MEMBER]),
    canAccessADV: state => checkUserAccess(state.authenticatedUser, [UserRoleType.ADV_MEMBER]),
    canAccessB2B: state => checkUserAccess(state.authenticatedUser, [UserRoleType.B2B_MEMBER]),
    canAccessSAV: state => checkUserAccess(state.authenticatedUser, [UserRoleType.SAV_MEMBER]),
    canAccessHandling: state => checkUserAccess(state.authenticatedUser, [UserRoleType.HANDLING_MEMBER]),
    canUnlockPurchasePayment: state => checkUserAccess(state.authenticatedUser, [UserRoleType.ADMIN]),
    canManageWithinSection: (state, _getters, _rootState, rootGetters) => {
      if (!rootGetters['app-section/activeSection'] && !checkUserAccess(state.authenticatedUser, [UserRoleType.ADMIN]))
        return;
      return (
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_MANAGER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_MANAGER], {
          currentSection: rootGetters['app-section/activeSection'].code
        }) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.ADMIN])
      );
    },
    canScanDeviceWithinSection: (state, _getters, _rootState, rootGetters) => {
      if (!rootGetters['app-section/activeSection'] && !checkUserAccess(state.authenticatedUser, [UserRoleType.ADMIN]))
        return;
      return (
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_SCANNER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_SCANNER], {
          currentSection: rootGetters['app-section/activeSection'].code
        })
      );
    },
    canAccessProductionOrders: state => checkUserAccess(state.authenticatedUser, [UserRoleType.PRODUCTION_ORDERS]),
    canAccessPickings: state => {
      return (
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_MANAGER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_MANAGER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.ASSOCIATION_MEMBER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.ADMIN])
      );
    },
    isManager: state => {
      return (
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_ALL_MANAGER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.TECHNICAL_SELF_MANAGER]) ||
        checkUserAccess(state.authenticatedUser, [UserRoleType.ADMIN])
      );
    }
  }
};

export default options;
