import { createStore } from 'vuex';

import alert, { AlertState } from './store/alert.store';
import appSection, { AppSectionState } from './store/app-section.store';
import auth, { AuthState } from './store/auth.store';
import { CageState, default as cage } from './store/cage.store';
import commission, { CommissionState } from './store/commission.store';
import changelog, { ChangelogState } from './store/changelog.store';
import comment, { CommentState } from './store/comment.store';
import control, { ControlState } from './store/control.store';
import dataExport, { DataExportState } from './store/data-export.store';
import deposit, { DepositState } from './store/deposit.store';
import deviceFailure, { DeviceFailuresState } from './store/device-failures.store';
import grade, { GradesState } from './store/grade.store';
import device, { DeviceState } from './store/device.store';
import error, { ErrorState } from './store/error.store';
import failures, { FailureState } from './store/failures.store';
import file, { FileState } from './store/file.store';
import focus, { FocusState } from './store/focus.store';
import globalSettings, { GlobalSettingsState } from './store/global-settings.store';
import location, { LocationState } from './store/location.store';
import marketplaceSku, { MarketplaceSkuState } from './store/marketplace-sku.store';
import matchError, { MatchErrorState } from './store/match-error.store';
import match, { MatchState } from './store/match.store';
import modal, { ModalState } from './store/modal.store';
import orderLine, { OrderLineState } from './store/order-line.store';
import orderReturn, { OrderReturnState } from './store/order-return.store';
import order, { OrderState } from './store/order.store';
import printer, { PrinterState } from './store/printer.store';
import processStep, { ProcessStepState } from './store/process-steps.store';
import processTask, { ProcessTaskState } from './store/process-tasks.store';
import productionOrder, { ProductionOrderState } from './store/production-order.store';
import purchaseQuery, { PurchaseQueryState } from './store/purchase-query.store';
import purchase, { PurchaseState } from './store/purchase.store';
import reference, { ReferenceState } from './store/reference.store';
import retrieval, { RetrievalState } from './store/retrieval.store';
import skuDifferenceCost, { SkuDifferenceCostState } from './store/sku-difference-cost.store';
import stock, { StockState } from './store/stock.store';
import supplier, { SupplierState } from './store/supplier.store';
import picking, { PickingState } from './store/picking.store';
import user, { UserState } from './store/user.store';
import table, { TableState } from './store/table.store';
import marketplace, { MarketplaceState } from './store/marketplace.store';

export interface RootState {
  'app-section': AppSectionState;
  'data-export': DataExportState;
  'global-settings': GlobalSettingsState;
  'match-error': MatchErrorState;
  'process-step': ProcessStepState;
  'process-task': ProcessTaskState;
  'purchase-query': PurchaseQueryState;
  'production-order': ProductionOrderState;
  'marketplace-sku': MarketplaceSkuState;
  'order-return': OrderReturnState;
  'sku-difference-cost': SkuDifferenceCostState;
  'order-line': OrderLineState;
  auth: AuthState;
  cage: CageState;
  comment: CommentState;
  changelog: ChangelogState;
  commission: CommissionState;
  control: ControlState;
  device: DeviceState;
  deviceFailure: DeviceFailuresState;
  grade: GradesState;
  deposit: DepositState;
  retrieval: RetrievalState;
  error: ErrorState;
  failures: FailureState;
  file: FileState;
  location: LocationState;
  order: OrderState;
  user: UserState;
  stock: StockState;
  marketplace: MarketplaceState;
  match: MatchState;
  modal: ModalState;
  printer: PrinterState;
  purchase: PurchaseState;
  reference: ReferenceState;
  supplier: SupplierState;
  picking: PickingState;
  table: TableState<any>;
  focus: FocusState;
  alert: AlertState;
}

export default createStore({
  modules: {
    'app-section': appSection,
    'data-export': dataExport,
    'global-settings': globalSettings,
    'match-error': matchError,
    'process-step': processStep,
    'process-task': processTask,
    'purchase-query': purchaseQuery,
    'production-order': productionOrder,
    'marketplace-sku': marketplaceSku,
    'order-return': orderReturn,
    'sku-difference-cost': skuDifferenceCost,
    'order-line': orderLine,
    auth,
    cage,
    changelog,
    commission,
    comment,
    control,
    deposit,
    retrieval,
    device,
    deviceFailure,
    grade,
    error,
    failures,
    file,
    location,
    order,
    user,
    stock,
    marketplace,
    match,
    modal,
    printer,
    purchase,
    reference,
    supplier,
    picking,
    table,
    focus,
    alert
  },
  strict: process.env.NODE_ENV !== 'production'
});
