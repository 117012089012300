import { AppExternalError } from '@/core/errors/app-external-error.class';
import { Comment } from '../comments/comments.types';
import { Control, FaultyComponent } from '../controls/controls.types';
import { SaveBase64Options } from '../file/file.types';
import { Location } from '../locations/locations.types';
import { Match, MatchStatus } from '../matches/matches.types';
import { OrderLine } from '../order-lines/order-lines.types';
import { TablePaginationOptions } from '../tables/tables.types';
import { User } from '../users/users.types';
import { Grade } from '../grades/grades.types';

export interface Device extends Control {
  order_line?: OrderLine;
  battery_ratio: number;
  color: string;
  control_id: number;
  control?: Control;
  created_at: string;
  grade: string;
  grading_id?: number;
  grading?: Grade;
  location?: Location;
  marketplace_order_id: string;
  operator: User;
  os: string;
  purchase_date: string;
  purchase_price: number;
  rma_reason: number;
  serial_no: string;
  size: number;
  sku: string;
  stock_status_id: number;
  stock_id_after_control: number;
  supplier_id: number;
  supplier_reference: string;
  tax_scheme: 'TVA' | 'TVM';
  touchbar: boolean;
  updated_at: string;
  warranty: number;
  year: string;
  keyboard_us: boolean;
  match?: Match;
  last_comment?: Comment;
  from_ajout_tool: boolean;
  is_eligible_painting: boolean;
  order_returns_nb?: number;
  booked_btob: boolean;
  eligible_polarizer: boolean;
}

export interface DevicesListOptions {
  filters?: Partial<Record<keyof Device, string[]>> & {
    fulltext: string;
    match_status: MatchStatus;
    stock_status_id: number[];
  };
  pagination?: TablePaginationOptions;
}

export type DeviceReportFile = SaveBase64Options;

export class InvalidReportError extends AppExternalError {
  constructor(id: number) {
    super(`Le rapport de l'ordinateur ${id} n'a pas le format attendu.`);
  }
}

export type DevicePart =
  | 'apple_recall'
  | 'audio_ext'
  | 'audio_int_left'
  | 'audio_int_right'
  | 'backlighting'
  | 'battery'
  | 'battery_indicator'
  | 'bt'
  | 'cd'
  | 'cd_noisy'
  | 'connector'
  | 'connector_trackpad'
  | 'cpu_panic'
  | 'delamination'
  | 'ethernet'
  | 'fan'
  | 'firmware_blocked'
  | 'freeze'
  | 'graphic_card'
  | 'hd'
  | 'hdmi_port'
  | 'hinge'
  | 'icloud_blocked'
  | 'io_board'
  | 'jack'
  | 'keyboard'
  | 'keyboard_ap'
  | 'keyboard_verif'
  | 'liquid_traces'
  | 'magsafe'
  | 'mic'
  | 'missing_pads'
  | 'missing_screws'
  | 'motherboard'
  | 'no_battery_detected'
  | 'no_boot'
  | 'no_charge'
  | 'no_install'
  | 'no_light'
  | 'oxidation'
  | 'power_supply'
  | 'ram'
  | 'random_turn_off'
  | 'remote_blocked'
  | 'screen'
  | 'screen_tightening'
  | 'sd'
  | 'sensor'
  | 'slow'
  | 'temperature'
  | 'thunderbolt'
  | 'touch_id'
  | 'touchpad'
  | 'trackpad'
  | 'usb_c'
  | 'usb_left'
  | 'usb_right'
  | 'ventile'
  | 'webcam'
  | 'wifi';

export interface RelocateOptions {
  stockId: number;
  faulty: FaultyComponent[];
  reason: string;
}

export interface ImportUpdateStatusResponse {
  count: number;
}
