import { Module } from 'vuex';

import { RootState } from '@/store';
import { StockStatus } from '@/services/stocks-status/stocks-status.types';
import { stocksStatusService } from '@/services/stocks-status/stocks-status.service';

export interface StockState {
  stockStatus: StockStatus;
  stockLoading: boolean;
}

const state: StockState = {
  stockStatus: null,
  stockLoading: false
};

const options: Module<StockState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    setStock: ({ commit, dispatch }, stock: StockStatus | string | number): Promise<void> => {
      if (typeof stock === 'number' || typeof stock === 'string') return dispatch('getStock', stock);
      else commit('stockStatus', stock);
    },
    get: ({ commit, dispatch }, stockStatusId: number): Promise<void> => {
      commit('stockStatus', null);
      commit('stockLoading', true);

      return stocksStatusService
        .get(stockStatusId)
        .then(stock => stock && commit('stockStatus', stock))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('stockLoading', false));
    }
  },
  mutations: {
    stockStatus: (state, stockStatus) => (state.stockStatus = stockStatus),
    stockLoading: (state, isLoading) => (state.stockLoading = isLoading)
  }
};

export default options;
