import axios from '@/core/axios';

import { ControlReport } from './control-reports.types';

export class ControlReportsService {
  list(serial_no: string): Promise<ControlReport[]> {
    return axios
      .get<ControlReport[]>('/control-reports/list', { params: { serial_no: serial_no } })
      .then(resp => resp?.data);
  }
}

export const controlReportsService = new ControlReportsService();
