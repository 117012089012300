import { UserRoleType } from '@/services/users/users.types';
import { get } from 'lodash';
import { AppSection, AppSectionCode, AppSubSection, SectionsDico } from './app-sections.types';

class AppSectionsService {
  private allSections = {
    [AppSectionCode.ADMIN]: {
      public: false,
      code: AppSectionCode.ADMIN,
      name: 'Admin',
      desc: 'Gestion des utilisateurs, des références, etc.',
      icon: 'fas fa-user-lock'
    },
    [AppSectionCode.CONTROL]: {
      public: true,
      code: AppSectionCode.CONTROL,
      name: 'Contrôle',
      stockName: 'control',
      desc: 'Test et mise en stock des ordinateurs',
      icon: 'fas fa-search',
      subSections: [
        {
          code: 'new',
          name: 'Test de l’ordinateur'
        },
        {
          code: 'form',
          name: 'Ajout d’informations complémentaires'
        },
        {
          code: 'confirm',
          name: 'Impression de l’étiquette'
        }
      ]
    },
    [AppSectionCode.MATCHING]: {
      public: true,
      code: AppSectionCode.MATCHING,
      name: 'Association',
      stockName: 'match',
      desc: 'Rangement et collecte dans le stock',
      icon: 'fas fa-cubes',
      subSections: [
        {
          code: 'start',
          name: 'Démarrage du picking'
        },
        {
          code: 'retrieval',
          name: 'Picking en cours'
        },
        {
          code: 'summary',
          name: 'Résumé du picking'
        }
      ]
    },
    [AppSectionCode.PREPARATION]: {
      public: true,
      code: AppSectionCode.PREPARATION,
      name: 'Préparation',
      stockName: 'prepare',
      desc: "Processus de préparation de l'ordinateur",
      icon: 'fas fa-wrench',
      subSections: []
    },
    [AppSectionCode.EXPEDITION]: {
      public: true,
      code: AppSectionCode.EXPEDITION,
      name: 'Expédition',
      stockName: 'dispatch',
      desc: "Processus d'expédition de l'ordinateur",
      icon: 'fas fa-truck-loading',
      subSections: []
    },
    [AppSectionCode.PURCHASE]: {
      public: true,
      code: AppSectionCode.PURCHASE,
      name: 'Achats',
      access: [
        UserRoleType.TECHNICAL_SELF_MANAGER,
        UserRoleType.TECHNICAL_ALL_MANAGER,
        UserRoleType.ACHATS_MEMBER,
        UserRoleType.HANDLING_MEMBER
      ],
      desc: 'Gestion des achats et fournisseurs',
      icon: 'fas fa-shopping-basket',
      subSections: [
        {
          code: 'purchase-stocks',
          name: 'Stocks',
          icon: 'fas fa-barcode',
          public: true,
          access: [
            UserRoleType.TECHNICAL_SELF_MANAGER,
            UserRoleType.TECHNICAL_ALL_MANAGER,
            UserRoleType.ACHATS_MEMBER,
            UserRoleType.HANDLING_MEMBER
          ],
          subSections: [
            {
              code: 'stocks-mac',
              name: 'Mac'
            },
            {
              code: 'stocks-spare',
              name: 'Pièces détachées'
            },
            {
              code: 'stocks-consumable',
              name: 'Emballages'
            },
            {
              code: 'stocks-queries',
              name: 'Demandes RdP'
            }
          ]
        },
        {
          code: 'purchase-orders',
          name: 'Commandes',
          icon: 'fas fa-shopping-cart',
          public: true,
          access: [UserRoleType.ACHATS_MEMBER],
          subSections: [
            {
              code: 'orders-mac',
              name: 'Mac'
            },
            {
              code: 'orders-spare',
              name: 'Pièces détachées'
            },
            {
              code: 'orders-consumable',
              name: 'Emballages'
            },
            {
              code: 'orders-other',
              name: 'Autre'
            }
          ]
        },
        {
          code: 'purchase-receptions',
          name: 'Réception',
          icon: 'fas fa-parachute-box',
          public: true,
          access: [UserRoleType.ACHATS_MEMBER, UserRoleType.HANDLING_MEMBER],
          subSections: [
            {
              code: 'receptions-mac',
              name: 'Mac'
            },
            {
              code: 'receptions-spare',
              name: 'Pièces détachées'
            },
            {
              code: 'receptions-consumable',
              name: 'Emballages'
            },
            {
              code: 'receptions-other',
              name: 'Autre'
            }
          ]
        },
        {
          code: 'purchase-suppliers',
          name: 'Fournisseurs',
          icon: 'fas fa-address-book',
          public: true,
          access: [UserRoleType.ACHATS_MEMBER]
        }
      ]
    },
    [AppSectionCode.ADV]: {
      public: true,
      code: AppSectionCode.ADV,
      name: 'ADV',
      access: [UserRoleType.ADV_MEMBER],
      desc: "Gestion de l'administration des ventes",
      icon: 'fas fa-comment-dollar',
      subSections: []
    },
    [AppSectionCode.SAV]: {
      public: true,
      code: AppSectionCode.SAV,
      name: 'SAV/RC',
      access: [
        UserRoleType.SAV_MEMBER,
        UserRoleType.TECHNICAL_ALL_MANAGER,
        UserRoleType.B2B_MEMBER,
        UserRoleType.RC_MEMBER
      ],
      desc: 'Gestion des retours',
      icon: 'fas fa-arrow-down',
      subSections: []
    },
    [AppSectionCode.REPAIR]: {
      public: true,
      code: AppSectionCode.REPAIR,
      name: 'Réparation',
      stockName: 'internal_repair',
      desc: 'Processus de réparation des ordinateurs',
      icon: 'fas fa-toolbox',
      subSections: [
        {
          code: 'diag',
          name: 'DIAGNOSTIQUE'
        },
        {
          code: 'rep',
          name: 'REPARATIONS'
        }
      ]
    }
  };

  get sections(): AppSection[] {
    return Object.keys(this.allSections)
      .filter(code => !!this.allSections[code].public)
      .map(code => this.allSections[code]);
  }

  traverseSections(code: string, sections: AppSection[] | AppSubSection[], pastTree: string[] = []): string[] {
    let newTree = null;
    sections.forEach(section => {
      if (section.code === code) newTree = [...pastTree, code];
      else if (section.subSections?.length > 0) {
        const tree = this.traverseSections(code, section.subSections, [...pastTree, section.code]);
        if (tree) newTree = tree;
      }
    });
    return newTree;
  }

  createSectionsDico(sections, dico: SectionsDico = {}): SectionsDico {
    sections.forEach(section => {
      dico[section.code] = section;
      if (section.subSections) this.createSectionsDico(section.subSections, dico);
    });
    return dico;
  }

  navBackRoute(value: string, scan = false): string {
    const segments = value.substring(1).split('/');

    switch (segments[0]) {
      case AppSectionCode.ADMIN:
        if (!segments[1]) return '/';
        return '/admin';
      case AppSectionCode.CONTROL:
        switch (segments[1]) {
          case 'table':
            return '/control';
          case 'grading':
            return scan ? '/control/grading/scan' : '/control';
          default:
            return '/';
        }
      case AppSectionCode.MATCHING:
        switch (segments[2]) {
          case 'devices':
          case 'order-lines':
            return '/matching';
          default:
            return '/';
        }
      case AppSectionCode.PREPARATION:
        if (!segments[1]) return '/';
        else return scan ? '/prepare/process/scan' : '/prepare';
      case AppSectionCode.EXPEDITION:
        if (!segments[1]) return '/';
        else return scan ? '/dispatch/dispatch-process/scan' : '/dispatch';
      case AppSectionCode.PURCHASE:
        return '/';
      case AppSectionCode.ADV:
        if (!segments[1]) return '/';
        return '/adv';
      case AppSectionCode.SAV:
        if (!segments[1]) return '/';
        return '/sav';
      case AppSectionCode.REPAIR:
        if (!segments[1]) return '/';
        else return scan ? '/repair/process/scan' : '/repair';
      default:
        throw new Error('Cannot decode previous route');
    }
  }

  getSubSectionsOf(section: AppSectionCode): AppSubSection[] {
    return get(this.allSections, `${section}.subSections`, []);
  }

  isSubSectionOf(section: AppSectionCode, subSection: string): boolean {
    return !!this.getSubSectionsOf(section).find(s => s.code === subSection);
  }
}

export const appSectionsService = new AppSectionsService();
