import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import CommentsBox from '@/components/Misc/CommentsBox/CommentsBox.vue';

/**
 * This mixin is very similar to the errors-list mixin and is used to load comments linked to a match, device, etc.
 *
 * @param subject Can be 'match', 'device', 'order', etc.
 */
export const commentsBoxMixin = <T>(subject: string) =>
  defineComponent({
    components: { CommentsBox },
    computed: { ...mapState('comment', ['comments']), ...mapState(subject, [subject]) },
    methods: {
      ...mapActions('comment', ['indexComments', 'pushComment', 'clearComments']),
      sendComment(content: string) {
        if (!this[subject].id) return;
        if (subject === 'productionOrder') {
          this.pushComment({ subject: 'production-order', subjectId: this[subject].id, content });
        } else if (subject === '') {
          this.pushComment({ subject: 'cage', subjectId: this[subject].id, content });
        } else if (subject === 'orderReturn') {
          this.pushComment({ subject: 'order-return', subjectId: this[subject].id, content });
        } else if (subject === 'orderLine') {
          this.pushComment({ subject: 'order-line', subjectId: this[subject].id, content });
        } else if (subject === 'picking') {
          this.pushComment({ subject: 'picking', subjectId: this[subject].id, content });
        } else {
          this.pushComment({ subject, subjectId: this[subject].id, content });
        }
      }
    },
    watch: {
      [subject]: {
        immediate: true,
        handler(newValue: T, oldValue: T) {
          if (!oldValue && newValue) {
            if (subject === 'productionOrder') {
              this.indexComments({ subject: 'production-order', subjectId: this[subject].id });
            } else if (subject === 'orderReturn') {
              this.indexComments({ subject: 'order-return', subjectId: this[subject].id });
            } else if (subject === 'orderLine') {
              this.indexComments({ subject: 'order-line', subjectId: this[subject].id });
            } else if (subject === 'cage') {
              this.indexComments({ subject: 'cage', subjectId: this[subject].id });
            } else if (subject === 'picking') {
              this.indexComments({ subject: 'picking', subjectId: this[subject].id });
            } else {
              this.indexComments({ subject, subjectId: this[subject].id });
            }
          }
        }
      }
    },
    unmounted() {
      this.clearComments();
    }
  });
