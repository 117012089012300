import { BehaviorSubject } from 'rxjs';

import moment from 'moment';
import {
  FormControlOption,
  FormControlRaw,
  FormControlSize,
  FormControlType,
  FormControlValue
} from './form-control.types';
import { FormGroup } from './form-group.class';

export class FormControl<T> {
  name: string;
  parent: FormGroup<T>;
  label$: BehaviorSubject<string>;
  value$: BehaviorSubject<FormControlValue>;
  hidden$: BehaviorSubject<boolean>;
  errors$: BehaviorSubject<string[]>;
  options$?: BehaviorSubject<FormControlOption[]>;
  split$: BehaviorSubject<number>;
  required$: BehaviorSubject<boolean>;
  unit: string;
  type: FormControlType;
  placeholder?: string;
  valid: boolean;
  touched: boolean;
  dirty: boolean;
  default: string | number;
  disabled: boolean;
  maxlength: number;
  min: number;
  max: number;
  step: string;
  size: FormControlSize;
  subtext: string;
  validators: { func: (value: FormControlValue) => boolean; message: string }[];
  afterChange?: (value: FormControlValue, formGroup: FormGroup<T>, hasChanged?: boolean) => Promise<void>;
  enableAllField: boolean;

  get value(): FormControlValue {
    if (this.type === 'number') return +this.value$.value;
    else return this.value$.value;
  }

  set value(value: FormControlValue) {
    const hasChanged = value !== this.value;
    this.value$.next(value);
    this.errors = [];
    this.parent?.change$?.next();
    if (this.afterChange) this.afterChange(value, this.parent, hasChanged);
  }

  get options(): FormControlOption[] {
    return this.options$.value;
  }

  set options(options: FormControlOption[]) {
    this.options$.next(options);
  }

  get hidden(): boolean {
    return this.hidden$.value;
  }

  set hidden(hidden: boolean) {
    this.hidden$.next(hidden);
  }

  get label(): string {
    return this.label$.value;
  }

  set label(label: string) {
    this.label$.next(label);
  }

  get split(): number {
    return this.split$.value;
  }

  set split(split: number) {
    this.split$.next(split);
  }

  get required(): boolean {
    return this.required$.value;
  }

  set required(required: boolean) {
    this.required$.next(required);
  }

  get errors(): string[] {
    return this.errors$.value;
  }

  set errors(errors: string[]) {
    this.errors$.next(errors);
  }

  constructor(control: FormControlRaw<T>, group?: FormGroup<T>) {
    this.name = control.name;
    this.parent = group;
    this.type = control.type;
    this.placeholder = control.placeholder || control.label + '...';
    this.unit = control.unit || '';
    this.disabled = control.disabled || false;
    this.valid = true;
    this.touched = false;
    this.dirty = false;
    this.maxlength = control.maxlength;
    this.min = control.min;
    this.max = control.max;
    this.size = control.size || group?.size || 'medium';
    this.step = control.step;
    this.subtext = control.subtext;
    this.validators = control.validators || [];
    this.afterChange = control.afterChange;
    this.label$ = new BehaviorSubject(control.label);
    this.errors$ = new BehaviorSubject([]);
    this.value$ = new BehaviorSubject(null);
    this.options$ = new BehaviorSubject(control.options || []);
    this.hidden$ = new BehaviorSubject(control.hidden);
    this.split$ = new BehaviorSubject(control.split || 1);
    this.required$ = new BehaviorSubject(control.required || false);
    this.enableAllField = control.enableAllField;

    /* We need to parse value because we receive strings */
    const parsedValue = this.parseValue(control.value == null ? control.default : control.value);
    this.value$.next(parsedValue);
    /**
     * We create some default validators based on passed options
     */
    if (this.type === 'number' && this.min !== undefined) {
      this.validators.push({
        func: (value: number) => value !== undefined && +value >= this.min,
        message: `Valeur min. : ${this.min}`
      });
    }
    if (this.type === 'number' && this.max !== undefined) {
      this.validators.push({
        func: (value: number) => value !== undefined && +value <= this.max,
        message: `Valeur max. : ${this.max}`
      });
    }
  }

  validate(): boolean {
    this.touched = false;
    this.dirty = false;
    const errors = [];
    if (this.disabled || this.hidden) return (this.valid = true);
    else if (this.required && this.isEmpty()) errors.push('Ce champ est obligatoire');
    else if (this.validators.length > 0) {
      this.validators.forEach(validator => {
        if (!validator.func(this.value)) errors.push(validator.message);
      });
    }
    this.errors = errors;
    return (this.valid = !this.errors.length);
  }

  private parseValue(value: FormControlValue): FormControlValue {
    if (this.type === 'number' && typeof value === 'string') {
      return value && parseFloat(value.replace(' ', '').replace(',', '.'));
    } else if (this.type === 'datetime-local' && typeof value === 'string') {
      return value && moment(value).format('yyyy-MM-DDTHH:mm');
    } else return value;
  }

  private isEmpty(): boolean {
    return (
      this.value == null || this.value === '' || this.value === [] || (this.type === 'number' && isNaN(+this.value))
    );
  }
}
