import { Module } from 'vuex';

import { globalSettingsService } from '@/services/global-settings/global-settings.service';
import { GlobalSettings } from '@/services/global-settings/global-settings.types';
import { RootState } from '@/store';

export interface GlobalSettingsState {
  settings: GlobalSettings;
  settingsLoading: boolean;
}

const state: GlobalSettingsState = {
  settings: null,
  settingsLoading: false
};

const options: Module<GlobalSettingsState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    indexGlobalSettings: async ({ commit, dispatch }): Promise<void> => {
      commit('settingsLoading', true);

      return globalSettingsService
        .index()
        .then(settings => settings && commit('settings', settings))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('settingsLoading', false));
    }
  },
  mutations: {
    settings: (state, globalSettings) => (state.settings = globalSettings),
    settingsLoading: (state, isLoading) => (state.settingsLoading = isLoading)
  },
  getters: {
    drives: state => (state?.settings?.drives ? Object.fromEntries(state?.settings?.drives || []) : {}),
    stocks_status: state =>
      state?.settings?.stocks_status ? Object.fromEntries(state?.settings?.stocks_status || []) : {},
    models: state => (state?.settings?.models ? Object.fromEntries(state?.settings?.models || []) : {}),
    colors: state => (state?.settings?.colors ? Object.fromEntries(state?.settings?.colors || []) : {}),
    currencies: state => (state?.settings?.currencies ? Object.fromEntries(state?.settings?.currencies || []) : {}),
    components: state => (state?.settings?.components ? Object.fromEntries(state?.settings?.components || []) : {}),
    grades: state => (state?.settings?.grades ? Object.fromEntries(state?.settings?.grades || []) : {}),
    procs: state => (state?.settings?.procs ? Object.fromEntries(state?.settings?.procs || []) : {}),
    years: state => (state?.settings?.years ? Object.fromEntries(state?.settings?.years || []) : {}),
    operatingSystemOptions: state => {
      if (!state?.settings?.operating_systems) return null;
      return [...state.settings.operating_systems.map(os => ({ value: os[0], label: os[1].display_name }))];
    },
    orderTypes: state =>
      state?.settings?.order_line_types ? Object.fromEntries(state?.settings?.order_line_types || []) : {},
    carriersOptions: state => {
      if (!state?.settings?.shipping_companies) return null;
      return [
        ...state.settings.shipping_companies.map(sc => ({
          value: sc[0],
          label: sc[1].display_name,
          types: sc[1].types
        }))
      ];
    },
    componentsOptions: state => {
      if (!state?.settings?.components) return null;
      return [...state.settings.components.map(os => ({ value: `replace_${os[0]}`, label: os[1] }))];
    },
    stocksOptions: state => {
      if (!state?.settings?.stocks_status) return null;
      return [
        ...state.settings.stocks_status.map(stock_status => {
          const data = { value: stock_status[0], label: stock_status[1].stock.display_name };
          if (stock_status[1].status) {
            data['label'] = stock_status[1].stock.display_name + ' / ' + stock_status[1].status.display_name;
          }
          return data;
        }),
        { value: 'all', label: 'Tous' }
      ];
    },
    associableStockId: state => {
      if (!state?.settings?.stocks_status) return null;
      return state.settings.stocks_status.filter(s => s[1].sellable).map(stocks_status => stocks_status[0]);
    },
    listStock: state => {
      if (!state?.settings?.stocks_status) return null;
      const finishedProductStock = state.settings.stocks_status.find(
        stocks_status => stocks_status[1].stock.name == 'finished_product'
      );

      return finishedProductStock[0];
    },
    matchStockId: state => {
      if (!state?.settings?.stocks_status) return null;
      const matchStock = state.settings.stocks_status.filter(s => s[1].sellable).map(stocks_status => stocks_status[0]);
      return matchStock;
    },
    finishedProductStockId: state => {
      if (!state?.settings?.stocks_status) return null;
      const finishedProductStock = state.settings.stocks_status
        .filter(s => s[1].sellable && s[1].status.name != 'finished_product')
        .map(stocks_status => stocks_status[0]);
      return finishedProductStock;
    }
  }
};

export default options;
